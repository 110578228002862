import { DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';
import DataStoreViewLogs from './DataStoreViewLogs';
import { DbLog } from '../../types/DbLog';
import DataStoreLogsService from '../../services/DataStoreLogsService';
import { useState } from 'react';
import { DbLogsEntries } from '../../types/DbLogsEnteries';

interface DataStoreLogsActionsProps {
    fileName: string;
    dataStoreUuid: string;
    disabled: boolean;
}

const DataStoreLogsActions = ({
    fileName,
    dataStoreUuid,
    disabled,
}: DataStoreLogsActionsProps) => {
    const [showModal, setShowModal] = useState(false);
    const [entries, setEntries] = useState<DbLogsEntries>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleViewLogs = async () => {
        setIsLoading(true);
        try {
            const response = await DataStoreLogsService.getDataStoreLogsEntries(
                dataStoreUuid,
                fileName
            );
            setEntries(response);
            setShowModal(true);
        } catch (e) {
            console.log('error', e);
        }
        setIsLoading(false);
    };

    const downloadDataStoreLogs = async () => {
        setIsLoading(true);
        try {
            const response = await DataStoreLogsService.downloadDataStoreLogs(
                dataStoreUuid,
                fileName
            );

            const report = await response.blob();
            let filename = `${fileName}-DatastoreLogs`;
            const contentDisposition = response.headers.get(
                'Content-Disposition'
            );

            if (contentDisposition) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            await downloadLogs(report, filename);
        } catch (error) {
            console.error('Error downloading logs:', error);
        }
        setIsLoading(false);
    };

    const downloadLogs = async (reportFile: any, fileName: string) => {
        const url = window.URL.createObjectURL(reportFile);
        const reportFileLink = document.createElement('a');
        reportFileLink.href = url;
        document.body.appendChild(reportFileLink);
        reportFileLink.download = fileName;
        reportFileLink.click();
        window.URL.revokeObjectURL(url);
    };

    const menu = (
        <Menu>
            <DataStoreViewLogs
                entries={entries}
                showModal={showModal}
                setShowModal={setShowModal}
                downloadLogs={downloadDataStoreLogs}
                handleViewLogs={() => handleViewLogs()}
                fileName={fileName}
            />
            <Menu.Item key="downloadLogs" onClick={downloadDataStoreLogs}>
                <DownloadOutlined /> Download
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={['click']}
            disabled={disabled}
        >
            <Button icon={<CcxContextualMenuIcon />} loading={isLoading} />
        </Dropdown>
    );
};

export default DataStoreLogsActions;
