import {
    Row,
    Col,
    Alert,
    Switch,
    Button,
    Typography,
    DatePicker,
    Form,
    notification,
    Modal,
} from 'antd';
import numbro from 'numbro';
import { useEffect, useState } from 'react';
import CcxComponentProps from '../../core/CcxComponent';
import Backup from '../../types/Backup';
import BackupColumns from './BackupColumns';

import styles from './BackupsModal.module.less';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';
import CcxIconInfoCircleTwoTone from '../ccx/icons/CcxIconInfoCircleTwoTone';
import BackupService from '../../services/BackupService';
import CcxIconCloseCircleTwoTone from '../ccx/icons/CcxIconCloseCircleTwoTone';

dayjs.extend(utc);

type onCloseCallback = (submitted: boolean) => void;

interface Props extends CcxComponentProps {
    visible?: boolean;
    currentBackup?: Backup;
    dataStore?: string;
    onclose: onCloseCallback;
}

function BackupsModal({
    testId = 'BackupsModal',
    visible = false,
    dataStore,
    currentBackup,
    onclose,
}: Props) {
    const [point, setPoint] = useState<boolean>(false);
    const [formattedDate, setFormattedDate] = useState<string>();
    const [pointDateTime, setPointDateTime] = useState<
        moment.Moment | undefined
    >(undefined);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    const format = 'DD/MM/YYYY HH:mm UTC';

    const [form] = Form.useForm();

    const { Text } = Typography;

    const formatSize = (size: undefined | number) => {
        return numbro(size).format({
            output: 'byte',
            base: 'binary',
            spaceSeparated: true,
            mantissa: 2,
        });
    };

    const handleSaveClick = () => {
        if (currentBackup && dataStore) {
            return new Promise(async (resolve, reject) => {
                try {
                    await BackupService.restoreBackup(
                        dataStore,
                        currentBackup?.backupId,
                        point && pointDateTime
                            ? pointDateTime?.toISOString()
                            : undefined
                    );

                    notification.open({
                        message: 'Restore Backup',
                        description: 'The backup will be restored soon.',
                        icon: <CcxIconInfoCircleTwoTone />,
                    });

                    Modal.destroyAll();

                    onclose && onclose(true);

                    resolve(null);
                } catch (e) {
                    notification.open({
                        message: 'Restore Backup',
                        description: `There was an error restoring the backup. ${e}`,
                        icon: (
                            <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                        ),
                    });

                    console.error(e);

                    reject();
                }
            }).catch(() => console.log('Oops errors!'));
        }
    };

    useEffect(() => {
        if (currentBackup && currentBackup?.startedAt) {
            setFormattedDate(
                dayjs.utc(currentBackup?.startedAt).format(format)
            );
        }
    }, [currentBackup, point]);

    const getDisabledHours = (
        start: number,
        end: number,
        increment: number
    ): number[] => {
        const disabledHours = [];
        for (let hour = start; hour < end; hour += increment) {
            disabledHours.push(hour);
        }
        return disabledHours;
    };

    const getCurrentDayDisabledHours = (currentHour: number): number[] => {
        return getDisabledHours(currentHour + 1, 24, 1);
    };

    const getDisabledHoursForStartedAt = (startHour: number): number[] => {
        return getDisabledHours(0, startHour, 1);
    };

    const getDisabledMinutes = (
        selectedHour: number,
        referenceHour: number,
        referenceMinute: number,
        isPast: boolean
    ): number[] => {
        if (selectedHour === referenceHour) {
            return [...Array(60).keys()].filter((minute) =>
                isPast ? minute > referenceMinute : minute < referenceMinute
            );
        }
        return [];
    };

    const getCurrentDayDisabledMinutes = (
        selectedHour: number,
        currentHour: number,
        currentMinute: number
    ): number[] => {
        return getDisabledMinutes(
            selectedHour,
            currentHour,
            currentMinute,
            true
        );
    };

    const getDisabledMinutesForStartedAt = (
        selectedHour: number,
        startHour: number,
        startMinute: number
    ): number[] => {
        return getDisabledMinutes(selectedHour, startHour, startMinute, false);
    };

    const disabledTime = (currentDate: moment.Moment | null) => {
        const currentBackupStartedAt = moment(currentBackup?.startedAt);
        currentDate = currentDate ?? moment();
        const dateToday = moment();
        const isToday = currentDate.isSame(dateToday, 'day');
        const currentHour = dateToday.hour();
        const currentMinute = dateToday.minute();
        const startHour = currentBackupStartedAt.hour();
        const startMinute = currentBackupStartedAt.minute();

        if (isToday) {
            return {
                disabledHours: () => getCurrentDayDisabledHours(currentHour),
                disabledMinutes: (selectedHour: number) =>
                    getCurrentDayDisabledMinutes(
                        selectedHour,
                        currentHour,
                        currentMinute
                    ),
            };
        } else if (currentBackupStartedAt.isSame(currentDate, 'day')) {
            return {
                disabledHours: () => getDisabledHoursForStartedAt(startHour),
                disabledMinutes: (selectedHour: number) =>
                    getDisabledMinutesForStartedAt(
                        selectedHour,
                        startHour,
                        startMinute
                    ),
            };
        }

        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
        };
    };

    useEffect(() => {
        if (!visible) {
            setPointDateTime(undefined);
            form.setFieldsValue({
                PointDateTime: null,
            });
        }
    }, [form, visible]);

    const handleDateTimeChange = (date: moment.Moment | null): void => {
        if (!date) return;
        setPointDateTime(moment.utc(date));
        setButtonDisabled(false);
    };

    const disabledDate = (current: moment.Moment) => {
        const endedAt = moment.utc(currentBackup?.endedAt);
        const currentDate = moment.utc();

        return (
            current &&
            !(
                current.isSameOrAfter(endedAt, 'day') &&
                current.isSameOrBefore(currentDate, 'day')
            )
        );
    };

    return (
        <Modal
            title={'Restore a backup'}
            open={visible}
            data-testid={`${testId}Modal`}
            width={600}
            onCancel={() => onclose(false)}
            footer={
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Alert
                            message="You are going to restore a backup"
                            description={
                                <span style={{ textAlign: 'start' }}>
                                    You are about to restore a backup created on{' '}
                                    <strong>{formattedDate}</strong>. This
                                    process will completely overwrite your
                                    current data and all changes since your last
                                    backup will be lost.
                                </span>
                            }
                            style={{ textAlign: 'start' }}
                            type="warning"
                            showIcon
                        />
                    </Col>
                    <Col span={16}>
                        {point && (
                            <>
                                <Text type="danger">*</Text>Required
                            </>
                        )}
                    </Col>
                    <Col>
                        <Button
                            data-testid={`${testId}CancelButton`}
                            onClick={() => onclose(false)}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={handleSaveClick}
                            data-testid={`${testId}SubmitButton`}
                            type="primary"
                            disabled={buttonDisabled}
                        >
                            Restore
                        </Button>
                    </Col>
                </Row>
            }
        >
            <h4 data-testid={`${testId}TitleInfo`}>
                <strong>Backup info</strong>
            </h4>

            <Row gutter={24}>
                <Col>
                    <BackupColumns
                        title="Backup ID"
                        subTitle={currentBackup?.backupId}
                    />
                </Col>
                <Col>
                    <BackupColumns
                        title="Type"
                        subTitle={currentBackup?.backupType}
                    />
                </Col>
                <Col>
                    <BackupColumns
                        title="Size"
                        last={true}
                        subTitle={formatSize(currentBackup?.size)}
                    />
                </Col>
            </Row>

            <h4
                data-testid={`${testId}TitleSettings`}
                className={styles.BackupsModalSettings}
            >
                <strong>Restore settings</strong>
            </h4>

            <Form form={form} layout="vertical">
                <Row gutter={[24, 8]}>
                    <Col className={styles.BackupsModalSwitchText}>
                        Use point in time recovery
                    </Col>
                    <Col>
                        <Form.Item noStyle>
                            <Form.Item name="point">
                                <Switch onChange={setPoint} />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
                {point && (
                    <Row gutter={[24, 16]}>
                        <Col span={24}>
                            Choose a specific point in time you want to go back
                            to.
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="pointDateTime"
                                label="Date and Time"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select a date and time',
                                    },
                                ]}
                            >
                                <DatePicker
                                    showTime
                                    format={`DD/MM/YYYY HH:mm:ss`}
                                    className={styles.BackupsModalDatePicker}
                                    onChange={handleDateTimeChange}
                                    disabledDate={disabledDate}
                                    disabledTime={disabledTime}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
}

export default BackupsModal;
