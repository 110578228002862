import styles from './DatastoreLogs.module.less';
import AppTable from '../AppTable';
import CcxComponentProps from '../../core/CcxComponent';
import DeploymentsItem from '../../types/DeploymentsItem';
import DataStoreLogsActions from './DataStoreLogsActions';
import AppEmpty from '../AppEmpty';
import useDataStoreEntries from '../../core/hooks/useDataStoreEntries';
import DbLogs from '../../types/DbLogs';
import { useEffect, useState } from 'react';
import { DbLogsEntries } from '../../types/DbLogsEnteries';
import { DbLogEntry } from '../../types/DbLogEntry';
import moment from 'moment';
import LazyLoader from '../LazyLoader';

interface Props extends CcxComponentProps {
    readonly currentDeployment?: DeploymentsItem | null | undefined;
    readonly dataStoreUuid: string;
    readonly logs: DbLogs;
}

const DatastoreLogs = ({ currentDeployment, dataStoreUuid, logs }: Props) => {
    const [logsTable, setLogsTable] = useState();
    const { dataStoreEntries, loading } = useDataStoreEntries(
        dataStoreUuid,
        logs
    );

    useEffect(() => {
        if (dataStoreEntries) groupedLogs();
    }, [dataStoreEntries]);

    const groupedLogs = () => {
        let newData: any = [];
        const seenFiles = new Set();

        logs.files.forEach((log: any) => {
            dataStoreEntries?.forEach((entries: any) => {
                entries?.entries?.forEach((entry: any) => {
                    if (entry.filename === log.name) {
                        if (!seenFiles.has(log.name)) {
                            newData.push({
                                name: log.name,
                                when: findLatestEntry(entries),
                            });
                            seenFiles.add(log.name);
                        }
                    }
                });
            });
        });

        logs.files.forEach((log: any) => {
            if (!seenFiles.has(log.name)) {
                newData.push({
                    name: log.name,
                });
            }
        });

        setLogsTable(newData);
    };

    const findLatestEntry = (entries: DbLogsEntries | undefined) => {
        let latestEntry: DbLogEntry | undefined;

        entries?.entries.forEach((entry: DbLogEntry) => {
            if (!latestEntry) {
                latestEntry = entry;
            } else if (entry.timestamp > latestEntry.timestamp) {
                latestEntry = entry;
            }
        });

        return latestEntry?.timestamp;
    };

    const logsColumns = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: 'When',
            key: 'when',
            dataIndex: 'when',
            render: (timeStamp: string, record: any) => {
                return record?.when ? (
                    <div>{moment(record?.when).format('YYYY-MM-DD HH:mm')}</div>
                ) : (
                    <></>
                );
            },
        },
        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'actions',
            align: 'right',
            width: 100,
            render: (_: any, record: any) => {
                return (
                    <DataStoreLogsActions
                        fileName={record?.name}
                        dataStoreUuid={dataStoreUuid}
                        disabled={!record?.when}
                    />
                );
            },
        },
    ];

    return currentDeployment && logs ? (
        <section className={styles.DatastoreLogs}>
            <h4 className={styles.DbLogsLabel}>Logs</h4>
            {loading ? (
                <LazyLoader type="row" />
            ) : (
                <AppTable
                    columns={logsColumns}
                    data={logsTable}
                    rowKey="logs"
                    expandable={false}
                    pagination={{
                        hideOnSinglePage: true,
                        pageSize: 5,
                        position: ['bottomCenter'],
                    }}
                />
            )}
        </section>
    ) : (
        <AppEmpty message="There are no logs available yet" />
    );
};

export default DatastoreLogs;
