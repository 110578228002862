import { DbLogEntry } from './DbLogEntry';

interface DbLogsEntriesInterface {
    entries: DbLogEntry[];
}

export class DbLogsEntries implements DbLogsEntriesInterface {
    readonly entries: DbLogEntry[];

    constructor(props: DbLogsEntriesInterface) {
        this.entries = props.entries.map(
            (entry: DbLogEntry) => new DbLogEntry(entry)
        );
    }
}
