import { ReactElement } from 'react';
import styles from './DbDashboard.module.less';
import CloudInstance from '../../../types/CloudInstance';
import MysqlDbDashboard from './MysqlDbDashboard';
import PostgreSqlDbDashboard from './PostgreSqlDbDashboard';
import RedisDashboard from './RedisDashboard';
import MssqlDbDashboard from './MssqlDbDashboard';
import MySqlDbDashboardSummary from './MysqlDbDashboardSummary';
import PostgreSqlDbDashboardSummary from './PostgresSqlDbDashboardSummary';

type DbDashboardProps = {
    uuid: string;
    host_uuid: string;
    port: number;
    from?: string;
    to?: string;
    interval: number;
    node?: CloudInstance;
    hidden?: boolean;
    displaySummary?: boolean;
    onLoading?: Function;
    hostUUIDs: string[] | undefined;
    ports: number[] | undefined;
};

function DbDashboard({
    uuid,
    host_uuid,
    port,
    from,
    to,
    interval,
    node,
    hidden,
    displaySummary,
    onLoading,
    hostUUIDs = [],
    ports = [],
}: DbDashboardProps): ReactElement {
    return (
        <section className={styles.DbDashboard}>
            {JSON.stringify(node) !== '{}'}
            {node?.isPostgreSql() &&
                (hostUUIDs instanceof Array && hostUUIDs.length > 0 ? (
                    <PostgreSqlDbDashboardSummary
                        uuid={uuid}
                        hostUUIDs={hostUUIDs}
                        port={port}
                        from={from}
                        to={to}
                        interval={interval}
                        hidden={hidden}
                        displaySummary={displaySummary}
                        onLoading={onLoading}
                    />
                ) : (
                    <PostgreSqlDbDashboard
                        uuid={uuid}
                        host_uuid={host_uuid}
                        port={port}
                        from={from}
                        to={to}
                        interval={interval}
                        hidden={hidden}
                        displaySummary={displaySummary}
                        onLoading={onLoading}
                    />
                ))}
            {node?.isMSSQL() && (
                <MssqlDbDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            )}
            {node?.isMySql() &&
                (hostUUIDs?.length ? (
                    <MySqlDbDashboardSummary
                        uuid={uuid}
                        hostUUIDs={hostUUIDs}
                        ports={ports}
                        from={from}
                        to={to}
                        interval={interval}
                        hidden={hidden}
                        displaySummary={displaySummary}
                        onLoading={onLoading}
                    />
                ) : (
                    <MysqlDbDashboard
                        uuid={uuid}
                        host_uuid={host_uuid}
                        port={port}
                        from={from}
                        to={to}
                        interval={interval}
                        hidden={hidden}
                        displaySummary={displaySummary}
                        onLoading={onLoading}
                    />
                ))}
            {node?.isRedis() && (
                <RedisDashboard
                    uuid={uuid}
                    host_uuid={host_uuid}
                    port={port}
                    from={from}
                    to={to}
                    interval={interval}
                    node={node}
                    hidden={hidden}
                    displaySummary={displaySummary}
                    onLoading={onLoading}
                />
            )}
        </section>
    );
}

export default DbDashboard;
