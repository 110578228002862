import {
    DownloadOutlined,
    FileTextOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Menu, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './DataStoreViewLogs.module.less';
import moment from 'moment';
import { DbLogsEntries } from '../../types/DbLogsEnteries';
import { DbLogEntry } from '../../types/DbLogEntry';

interface DataStoreViewLogsProps {
    readonly testId?: string;
    readonly handleViewLogs?: () => void;
    readonly entries?: DbLogsEntries;
    readonly showModal: boolean;
    readonly fileName: string;
    readonly setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    readonly downloadLogs: () => void;
}

function DataStoreViewLogs({
    testId,
    entries,
    showModal,
    fileName,
    downloadLogs,
    setShowModal,
    handleViewLogs,
}: DataStoreViewLogsProps) {
    const [localEntries, setLocalEntries] = useState<
        DbLogEntry[] | undefined
    >();
    const [latestEntry, setLatestEntry] = useState<DbLogEntry | undefined>();
    const [showAll, setShowAll] = useState<boolean>(false);

    const findLatestEntry = (entries: DbLogsEntries | undefined) => {
        let latestEntry: DbLogEntry | undefined;
        entries?.entries.forEach((entry: DbLogEntry) => {
            if (!latestEntry) {
                latestEntry = entry;
            } else if (entry.timestamp > latestEntry.timestamp) {
                latestEntry = entry;
            }
        });
        return latestEntry;
    };

    useEffect(() => {
        if (entries && !latestEntry) {
            const tempLatestEntry = findLatestEntry(entries);
            setLatestEntry(tempLatestEntry);
            showFirst10Logs();
        }
    }, [entries, latestEntry]);

    const showFirst10Logs = () => {
        const tempEntries: DbLogEntry[] | undefined = entries
            ? entries?.entries.slice(0, 10)
            : [];
        setLocalEntries(tempEntries);
    };

    const showAllLogs = () => {
        setShowAll(true);
        setLocalEntries(entries?.entries);
    };

    const hideAllLogs = () => {
        setShowAll(false);
        showFirst10Logs();
    };

    return (
        <>
            <Menu.Item key="viewLogs" onClick={handleViewLogs}>
                <FileTextOutlined /> View Log
            </Menu.Item>
            <Modal
                title={`Log: ${fileName}`}
                open={showModal}
                data-testid={`${testId}Modal`}
                width={1100}
                onCancel={() => setShowModal(!showModal)}
                footer={
                    <Row justify="end">
                        <Col>
                            <Button
                                type="primary"
                                data-testid={`${testId}CancelButton`}
                                onClick={() => setShowModal(!showModal)}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                }
            >
                <Row justify={'space-between'}>
                    <Col>
                        <h4 className={styles.LogInnerHeading}>Log Details</h4>
                    </Col>
                    <Col>
                        <Button
                            className={styles.DownloadLogsButton}
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={downloadLogs}
                        >
                            Download
                        </Button>
                    </Col>
                </Row>
                <Row
                    gutter={[16, 16]}
                    justify={'center'}
                    className={styles.DataStoreDetailsCard}
                >
                    <Col span={2}>
                        <div className={styles.DataStoreViewLogsIcon}>
                            <InfoCircleOutlined style={{ fontSize: '32px' }} />
                        </div>
                    </Col>
                    <Col span={22}>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <p className={styles.LogDetailsLabel}>
                                    Last written date
                                </p>
                                <p className={styles.LogDetailsText}>
                                    {moment(latestEntry?.timestamp).format(
                                        'MMMM D, YYYY, HH:mm'
                                    )}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className={styles.LogDetailsLabel}>
                                    Last fetched date
                                </p>
                                <p className={styles.LogDetailsText}>
                                    {moment(latestEntry?.timestamp).format(
                                        'MMMM D, YYYY, HH:mm'
                                    )}
                                </p>
                            </Col>
                            <Col span={8}>
                                <p className={styles.LogDetailsLabel}>
                                    Log file name
                                </p>
                                <p className={styles.LogDetailsText}>
                                    {latestEntry?.filename
                                        ? latestEntry?.filename
                                        : fileName}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr className={styles.LogsDivider} />
                <h4 className={styles.LogInnerHeading}>Log Messages</h4>
                <Row justify={'center'} className={styles.DataStoreDetailsCard}>
                    {localEntries?.map((entry: DbLogEntry, index: number) => (
                        <Col span={24} key={index}>
                            {formatLogMessage(entry)}
                        </Col>
                    ))}
                    {entries?.entries && entries?.entries?.length > 10 && (
                        <Col span={4}>
                            <Button
                                type="link"
                                onClick={showAll ? hideAllLogs : showAllLogs}
                            >
                                View {showAll ? 'less' : 'more'} message
                            </Button>
                        </Col>
                    )}
                </Row>
            </Modal>
        </>
    );
}

export default DataStoreViewLogs;

const formatLogMessage = ({ message, hostname, timestamp }: DbLogEntry) => {
    const dateMatch = message.match(
        /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z)|(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/
    );

    if (dateMatch) {
        const date = dateMatch[0];
        const restOfLog = message.replace(date, '');

        return (
            <div>
                <b>{`${hostname} | ${date} | `}</b>
                {restOfLog}
            </div>
        );
    }

    return (
        <p>
            <b>{`${hostname} `}</b>
            {message}
        </p>
    );
};
